import moment from "moment";
import { ForeignEntry } from "./ForeignEntry";
import Site from "./Site";
import ArticleService from "@/services/ArticleService";
export default class Book {
    constructor(data) {
        this.number = 1;
        this.year = (new Date()).getFullYear();
        this.date = moment().format('YYYY-MM-DD');
        this.validity_date = '';
        this.id_customer = new ForeignEntry();
        this.zone = '';
        this.festival = '';
        this.is_final = false;
        this.notes = '';
        this.sites = new Array();
        this.full_number = '';
        this.status = '';
        this.id = 0;
        if (data) {
            Object.assign(this, data);
            if (data.sites) {
                this.sites = data.sites.map(s => new Site(s));
            }
            else {
                this.sites = new Array();
            }
        }
    }
    clone() {
        let clone = new Book();
        clone.id = 0;
        clone.number = this.number;
        clone.year = (new Date()).getFullYear();
        clone.date = this.date;
        clone.validity_date = this.validity_date;
        clone.id_customer = this.id_customer;
        clone.zone = this.zone;
        clone.festival = this.festival;
        clone.on_date = this.on_date;
        clone.last_on_date = this.last_on_date;
        clone.accepted_date = this.accepted_date;
        clone.mount_date = this.mount_date;
        clone.unmount_date = this.unmount_date;
        clone.is_final = this.is_final;
        clone.notes = this.notes;
        this.sites.forEach(site => {
            clone.sites.push(site.clone());
        });
        clone.full_number = '';
        clone.status = this.status;
        clone.outOfStock = this.outOfStock;
        // let clone = new Book( this );
        // clone.id = 0;
        // this.sites.forEach( site => {
        //     clone.sites.push( site.clone() );
        // } );
        return clone;
    }
    get fullNumber() {
        if (this.full_number !== '') {
            return this.full_number;
        }
        else {
            return this.number + '/' + this.year;
        }
    }
    // Esto es para que se marquen en rojo las reservas
    get highlighted() {
        return this.outOfStock;
    }
    getOverview() {
        let articles = new Map();
        this.sites.forEach(site => {
            site.articles.forEach(article => {
                if (!articles.has(article.article.id)) {
                    articles.set(article.article.id, article.article);
                }
                let art = articles.get(article.article.id);
                art.quantity += article.quantity;
                articles.set(article.article.id, art);
            });
        });
        return Array.from(articles.values());
    }
    toNormalize() {
        let reg = Object.assign({}, this);
        delete (reg.id);
        delete (reg.full_number);
        delete (reg.status);
        return reg;
    }
    async getBase64Images() {
        var articleService = new ArticleService();
        this.sites.forEach(async (site) => {
            site.articles.forEach(async (article) => {
                if (article.article.images && article.article.images.length > 0 && article.article.images[article.imageIndex]) {
                    var image = article.article.images[article.imageIndex];
                    await articleService.getBase64Image(image.toString()).subscribe((base64Image) => {
                        article.article.images[article.imageIndex] = base64Image;
                        console.log('go');
                    });
                }
            });
        });
    }
}
