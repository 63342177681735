import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import JSPage from '@/classes/JSPage';
import BookService from '@/services/BookService';
import Book from '@/models/Book';
import { CBlocker } from '@/components/Blocker/CBlocker';
import Globals from '@/classes/Globals';
import Router from '@/classes/Router';
import BookMainPrint from './BookPage/Tabs/BookMainTab/BookMainPrint/BookMainPrint.vue';
let BooksPage = class BooksPage extends JSPage {
    constructor() {
        super(...arguments);
        this.bookService = new BookService();
        this.headers = [];
        this.filters = [];
        this.doPrint = false;
        this.printData = false;
        this.Book = Book;
    }
    created() {
        super.created();
    }
    main() {
        this.setHeaders();
        this.setFilters();
    }
    setHeaders() {
        this.headers = [
            { text: 'Número', value: 'full_number', align: 'start', sort_fields: ['number', 'year'], sortable: true },
            { text: 'Cliente', value: 'id_customer.strValue', align: 'start' },
            { text: 'Pedanía/Barrio', value: 'zone', align: 'start' },
            { text: 'Fiesta', value: 'festival', align: 'start' },
            { text: 'F. Encendido', value: 'on_date', align: 'start', type: IDataTableHeaderType.DATE },
            { text: 'F. Ult. Encendido', value: 'last_on_date', align: 'start', type: IDataTableHeaderType.DATE },
            { text: 'Estado', value: 'status', align: 'start' },
            { text: '', value: 'actions', align: 'end', sortable: false }
        ];
    }
    setFilters() {
        this.filters = [
            { text: 'Cliente, Población, Pedanía o Fiesta', value: 'customer.name·customer.city·zone·festival', content: '', toggle: true, type: 'text', width: '5' },
        ];
    }
    duplicateBook(item) {
        CBlocker.showConfirm('¿Deseas duplicar esta reserva?', async () => {
            this.bookService.get(item.id).subscribe(async (book) => {
                let newBook = new Book(book).clone();
                await this.bookService.getNextNumber().subscribe((next) => book.number = next);
                newBook.year = new Date().getFullYear();
                Globals.set('book', newBook);
                Router.goTo('/books/new');
            });
        }, () => CBlocker.hide());
    }
    print(item) {
        this.bookService.get(item.id).subscribe((book) => {
            this.printData = new Book(book);
            this.doPrint = true;
        });
    }
};
BooksPage = tslib_1.__decorate([
    Component({
        components: {
            DataTable, BookMainPrint
        }
    })
], BooksPage);
export default BooksPage;
